<template>
  <div>
    <h2 class="text-2xl font-bold">TangerineLife</h2>

    <div class="grid grid-cols-2 mb-4">
      <div class="col-span-1">
        <tabs :tabs="tabs" v-model="tab" />
      </div>
      <div class="col-span-1">
        <div class="relative">
           <search-form v-model="searchQuery.query" @submit="event => $refs.table.loadAjaxData()" />
        </div>
      </div>
    </div>

    <component
      :is="tab"
      :searchQuery="searchQuery"
      :search-field="$refs.searchField"
      :lenders="lenders"
    />
  </div>
</template>

<script>
export default {
  components: {
    repayments: () => import("./Repayments"),
    batch: () => import("./RepaymentBatch")
  },
  data() {
    return {
      searchQuery: "",
      tab: "repayments",
      tabs: [
        { name: "repayments", title: "Repayments" },
        { name: "batch", title: "Repayments Batch" },
      ],
      lenders: this.$options.resource([]),
    };
  },
  watch: {
    tab(tab) {
      if (this.$route.query.tab !== tab) {
        this.$router.replace({ ...this.$route, query: { tab } });
      }
    }
  },
  beforeMount() {
    this.getLenders();
  },
  mounted() {
    if (this.$route.query.tab?.match(/sent|disbursed/)) {
      this.tab = this.$route.query.tab;
    }
  },
  methods: {
    async getLenders() {
      this.lenders.loading = true;
      await this.sendRequest('admin.lenders.all', {
        success: response => {
          this.lenders.data = response.data.lenders;
        }
      });
      this.lenders.loading = false;
    },
  }
};
</script>
